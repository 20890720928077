<template>
  <div style="padding: 20px">
    <div style="text-align: right; margin-bottom: 20px;">
      <el-button type="danger" :loading="deleteLoading" @click="handleDeleteSnapshot">
        删除快照
      </el-button>
      <el-button type="primary" @click="handleAdd">新增</el-button>
    </div>

    <el-table :data="list" border stripe>
      <el-table-column prop="title" label="标题">
        <template slot-scope="scope">
          <div>
            <span>{{ scope.row.title }}</span>
            <el-button
              size="mini"
              icon="el-icon-edit"
              circle
              type="text"
              @click="handleEditTitle(scope.row)"
            ></el-button>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="nft_counts" label="关联藏品数量"></el-table-column>
      <el-table-column prop="batch_title" label="关联上新批次"></el-table-column>
      <el-table-column prop="snapshot_at" label="快照时间">
        <template slot-scope="scope">
          <div>
            <span>{{ scope.row.snapshot_at | formateDate }}</span>
            <el-button
              size="mini"
              icon="el-icon-edit"
              circle
              type="text"
              @click="handleEditSnapshot(scope.row)"
            ></el-button>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="reserve_status" label="预约购状态">
        <template slot-scope="scope">
          <el-switch
            :value="scope.row.reserve_status === 'enable'"
            @change="(val) => handleChangeReserveStatus(val, scope.row.id)"
          >
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column prop="priority_status" label="优先购状态">
        <template slot-scope="scope">
          <el-switch
            :value="scope.row.priority_status === 'enable'"
            @change="(val) => handleChangePriorityStatus(val, scope.row.id)"
          >
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="300" align="center">
        <template slot-scope="scope">
          <el-button size="mini" type="info" @click="handleView(scope.row)">
            查看
          </el-button>
          <el-button
            v-if="nodeENV === 'development'"
            size="mini"
            type="primary"
            @click="handleSnapshot(scope.row)"
          >
            快照
          </el-button>
          <el-button type="danger" size="small" @click="handleClickRemove(scope.row)">
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <CreatePriority
      :visible="visible"
      :batchList="batchList"
      :limitConfig="limits"
      :editData="editData"
      :dialogType="dialogType"
      @close="visible = false"
      @success="handleSuccess"
    />
    <el-dialog
      title=""
      :visible.sync="editTitleVisible"
      width="30%"
      @close="editTitleVisible = false"
    >
      <div>
        <el-input v-model="newTitle" placeholder="请输入标题" size="normal" clearable></el-input>
      </div>

      <span slot="footer">
        <el-button @click="editTitleVisible = false">取消</el-button>
        <el-button type="primary" @click="editTitle">确定</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="修改快照时间"
      :visible.sync="editSnapshotVisible"
      width="30%"
      @close="editSnapshotVisible = false"
    >
      <div>
        <el-date-picker
          style="width: 400px;"
          v-model="snapshot_at"
          type="datetime"
          size="normal"
          placeholder="选择快照时间"
          value-format="timestamp"
        >
        </el-date-picker>
      </div>

      <span slot="footer">
        <el-button @click="editSnapshotVisible = false">取消</el-button>
        <el-button type="primary" @click="editSnapshot">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import dayjs from "dayjs";
import {
  getPriorityPurchases,
  removePriorityPurchase,
  setPriorityPurchases,
  setPriorityPurchasesStatus,
  getTop1000Contribution,
  getBatchNFTList,
  getBatchList,
  getPurchaseLimitConfig,
  deleteSnapshot,
  eventsSnapshot,
} from "@/api/nft";
import CreatePriority from "./create.vue";

export default {
  components: {
    CreatePriority,
  },
  data() {
    return {
      nodeENV: process.env.NODE_ENV,
      list: [],
      // nfts: [],
      visible: false,
      dialogType: "add",
      form: {},
      rules: {
        title: [{ required: true, message: "请输入标题", trigger: "blur" }],
      },
      batchRules: [{ required: true, message: "请选择批次", trigger: "change" }],

      // loading: false,
      // ranking: 1000,
      // rankingContribution: 0,
      // rankingVisible: false,
      batchNFTs: [],
      batchList: [],
      batchTotal: 0,

      limits: [],
      editData: {},

      editTitleVisible: false,
      editSnapshotVisible: false,
      newTitle: "",
      id: "",
      batchNumber: "",
      snapshot_at: "",

      deleteLoading: false,
    };
  },
  filters: {
    formateDate: function(value) {
      if (!value || +value === 0) {
        return "-";
      }
      return dayjs(value * 1000).format("YYYY-MM-DD HH:mm:ss");
    },
  },

  mounted() {
    this.fetchData();
    this.getConfig();
    this.fetchBatchList();
  },
  methods: {
    async getConfig() {
      const { data } = await getPurchaseLimitConfig();
      if (Array.isArray(data.data)) {
        this.limits = [
          ...data.data,
          {
            type: "only",
            value: "",
            desc: "自定义",
          },
        ];
      }
    },
    async fetchData() {
      try {
        const { data } = await getPriorityPurchases();
        const items = data.data.items || [];
        this.list = [...items];
      } catch (error) {}
    },
    async fetchBatchList() {
      try {
        const { data } = await getBatchList({
          page: 1,
          page_size: 20,
          // is_priority_purchase: true,
        });
        this.batchList = data.data.items || [];
        this.batchTotal = data.data.total || 0;
      } catch (error) {
        this.batchList = [];
        this.batchTotal = 0;
      }
    },
    handleAdd() {
      const openedItem = this.list.find((item) => {
        return item.priority_status === "enable" || item.reserve_status === "enable";
      });
      if (openedItem) {
        this.$message.info("请先关闭其他开启中的上新数据");
        return;
      }

      this.editData = {};
      this.visible = true;
      this.dialogType = "add";
    },
    handleView(item) {
      // this.setForm(item);
      this.editData = {
        ...item,
      };
      this.dialogType = "detail";
      this.visible = true;
      // if (item.batch_number > 0) {
      //   this.fetchBatchNFTList(item.batch_number);
      // }
    },
    handleEdit(item) {
      this.editData = {
        ...item,
      };
      // this.setForm(item);
      this.dialogType = "edit";
      this.visible = true;
      // if (item.batch_number > 0) {
      //   this.fetchBatchNFTList(item.batch_number);
      // }
    },
    handleClickRemove(item) {
      this.$confirm("此操作将删除该批次, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.handleRemove(item);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    async handleRemove(item) {
      try {
        await removePriorityPurchase({ id: item.id });
        this.$message.success("删除成功");
        this.fetchData();
      } catch (error) {
        console.log(error);
      }
    },
    handleChangePriorityStatus(val, id) {
      setPriorityPurchasesStatus({
        id,
        priority_status: val ? "enable" : "disable",
      }).then(() => {
        this.fetchData();
      });
    },
    handleChangeReserveStatus(val, id) {
      setPriorityPurchasesStatus({
        id,
        reserve_status: val ? "enable" : "disable",
      }).then(() => {
        this.fetchData();
      });
    },

    handleSuccess() {
      this.fetchData();
      this.visible = false;
    },

    handleEditSnapshot(item) {
      this.editSnapshotVisible = true;
      this.newTitle = item.title;
      this.id = item.id;
      this.batchNumber = item.batch_number;
      this.snapshot_at = +item.snapshot_at > 0 ? +item.snapshot_at * 1000 : "";
    },

    async editSnapshot() {
      try {
        await setPriorityPurchases({
          id: this.id,
          title: this.newTitle,
          batch_number: this.batchNumber,
          snapshot_at: Math.floor(this.snapshot_at / 1000),
        });
        this.editSnapshotVisible = false;
        this.id = "";
        this.batchNumber = "";
        this.newTitle = "";
        this.snapshot_at = "";
        this.fetchData();
      } catch (error) {}
    },

    async handleEditTitle(item) {
      this.editTitleVisible = true;
      this.newTitle = item.title;
      this.id = item.id;
      this.batchNumber = item.batch_number;
      this.snapshot_at = +item.snapshot_at > 0 ? +item.snapshot_at * 1000 : "";
    },
    async editTitle() {
      try {
        await setPriorityPurchases({
          id: this.id,
          title: this.newTitle,
          batch_number: this.batchNumber,
          snapshot_at: Math.floor(this.snapshot_at / 1000),
        });
        this.editTitleVisible = false;
        this.id = "";
        this.batchNumber = "";
        this.newTitle = "";
        this.snapshot_at = "";
        this.fetchData();
      } catch (error) {}
    },
    async handleDeleteSnapshot() {
      if (this.deleteLoading) {
        return;
      }

      try {
        this.deleteLoading = true;
        await deleteSnapshot();
        this.$message.success("删除成功");
        this.deleteLoading = false;
      } catch (error) {
        console.log(error);
        this.deleteLoading = false;
      }
    },
    async handleSnapshot(item) {
      try {
        eventsSnapshot({
          snapshot_date: item.id,
        });
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="less">
.no-spin-button {
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
}
.priority-form-dialog {
  .el-form-item {
    margin-bottom: 6px;
  }
}
.priority-buy-edit-form {
  .el-form-item__label {
    padding-bottom: 0;
    font-weight: bold;
  }

  .img-card {
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    margin: 8px 8px 0 0;
    border-radius: 6px;
    overflow: hidden;

    img {
      vertical-align: middle;
    }
  }
}
</style>
