<template>
  <div>
    <el-dialog
      title="新增"
      :visible="visible"
      width="960px"
      @close="handleClose"
      class="priority-form-dialog"
    >
      <el-row :gutter="20">
        <el-col :span="12" :offset="0">
          <div>
            <el-form
              :model="form"
              ref="form"
              :rules="rules"
              :inline="false"
              size="normal"
              label-position="top"
              class="priority-buy-edit-form"
            >
              <el-form-item label="标题" prop="title">
                <el-input
                  style="width: 400px;"
                  v-model="form.title"
                  placeholder="请输入标题"
                  :maxlength="30"
                  show-word-limit
                  :disabled="dialogType === 'detail'"
                />
              </el-form-item>
              <el-form-item
                label="本次快照时间（快照后将锁定勋章，请运营认认真真核对）"
                prop="snapshot_at"
              >
                <el-date-picker
                  :disabled="dialogType === 'detail'"
                  style="width: 400px;"
                  v-model="form.snapshot_at"
                  type="datetime"
                  size="normal"
                  placeholder="选择快照时间"
                  value-format="timestamp"
                >
                </el-date-picker>
              </el-form-item>

              <el-form-item prop="batch_number">
                <span slot="label">
                  <div style="width: 386px; display: inline-flex; justify-content: space-between;">
                    <div>关联上新批次</div>
                    <div style="font-weight: normal;">上架总数：{{ totalNFT }}</div>
                  </div>
                </span>
                <div style="border: 1px solid #EBEEF5; width: 360px; padding: 20px;">
                  <el-select
                    style="width: 360px;"
                    v-model="form.batch_number"
                    placeholder="请选择批次"
                    @change="handleChangeBatch"
                    :disabled="dialogType === 'detail'"
                  >
                    <el-option
                      v-for="batch in batchList"
                      :label="batch.title"
                      :value="batch.batch_number"
                      :key="batch.batch_number"
                    />
                  </el-select>

                  <div v-if="form.batch_number > 0" style="padding-top: 10px">
                    <DisplayTime
                      :onSellAt="selectedBatchData.on_sell_at"
                      :priorityPurchaseAt="selectedBatchData.priority_purchase_at"
                      :reservePurchaseStart="selectedBatchData.reserve_purchase_start"
                      :reservePurchaseEnd="selectedBatchData.reserve_purchase_end"
                    />

                    <div style="padding: 8px 0">
                      <el-table style="width: 360px" height="250px" :data="batchNFTs" border stripe>
                        <el-table-column prop="name" label="藏品合集名称" />
                        <el-table-column prop="count" label="上架数量" align="center" />
                      </el-table>
                    </div>
                  </div>
                </div>
              </el-form-item>
            </el-form>
          </div>
        </el-col>
        <el-col :span="12" :offset="0">
          <div>
            <div style="display: flex; justify-content: space-between">
              <div style="font-weight: bold;">
                预约购条件配置
              </div>
              <div style="display: inline-flex;">
                <span style="margin-right: 10px;">活动状态</span>
                <el-switch v-model="reserveStatus" :disabled="dialogType === 'detail'" />
              </div>
            </div>
            <div style="background: #eee; padding: 12px; margin-top: 8px;">
              <div style="display: flex; align-items: center;">
                <div style="margin-right: 10px;">预约购必须持有的藏品条件</div>
                <el-dropdown
                  trigger="click"
                  size="default"
                  @command="handleCommand"
                  :disabled="dialogType === 'detail'"
                >
                  <el-button
                    :disabled="dialogType === 'detail'"
                    icon="el-icon-plus"
                    size="mini"
                    circle
                  />
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item
                      v-for="item in limitTypes"
                      :key="item.key"
                      :command="item.command"
                      :disabled="item.disabled"
                    >
                      {{ item.title }}
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>

              <div v-for="(limit, index) in selectedLimit" :key="index">
                <LimitRow
                  :limit="limit"
                  @remove="handleRemoveLimit(index)"
                  @changeCount="handleChangeCount"
                  @changeValue="handleChangeValue"
                  :index="index"
                  :dialogType="dialogType"
                />
              </div>
              <div>
                <div style="margin: 20px 0 10px 0;">参与预约购最低权益值</div>
                <div style="display: flex; align-items: center">
                  <el-input
                    v-model="reserveRightValue"
                    size="normal"
                    style="margin-right: 8px; width: 286px"
                    :disabled="true"
                  />
                  <el-button
                    type="primary"
                    size="small"
                    :disabled="dialogType === 'detail'"
                    @click="handleClickGetRightValue('reserve')"
                  >
                    获取
                  </el-button>
                </div>
              </div>
              <el-divider style="margin: 8px 0" />
              <div>
                <div style="margin: 0px 0 10px 0;">可预约几副作品比对</div>
                <div>
                  <el-input
                    v-model="reserveNum"
                    size="normal"
                    style="margin-right: 8px; width: 286px"
                    :disabled="dialogType === 'detail'"
                  >
                    <div style="padding: 0 10px; height: 40px; line-height: 40px;" slot="suffix">
                      个
                    </div>
                  </el-input>
                </div>
              </div>
            </div>
          </div>

          <div style="margin: 20px 0 0 0;">
            <div style="display: flex; justify-content: space-between">
              <div style="font-weight: bold;">
                优先购条件配置
              </div>
              <div style="display: inline-flex;">
                <span style="margin-right: 10px;">活动状态</span>
                <el-switch v-model="priorityStatus" :disabled="dialogType === 'detail'" />
              </div>
            </div>
            <div style="background: #eee; padding: 12px; margin-top: 8px;">
              <div style="display: flex; align-items: center;">
                <div style="margin-right: 10px;">优先购必须持有的藏品条件</div>
                <el-dropdown
                  trigger="click"
                  size="default"
                  @command="handlePCommand"
                  :disabled="dialogType === 'detail'"
                >
                  <el-button
                    icon="el-icon-plus"
                    size="mini"
                    circle
                    :disabled="dialogType === 'detail'"
                  ></el-button>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item
                      v-for="item in limitPTypes"
                      :key="item.key"
                      :command="item.command"
                      :disabled="item.disabled"
                    >
                      {{ item.title }}
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>

              <div v-for="(limit, index) in selectedPLimit" :key="index">
                <LimitRow
                  :limit="limit"
                  @remove="handlePRemoveLimit(index)"
                  @changeCount="handlePChangeCount"
                  @changeValue="handlePChangeValue"
                  :index="index"
                  :dialogType="dialogType"
                />
              </div>

              <div>
                <div style="margin: 20px 0 10px 0;">参与优先购最低权益值</div>
                <div style="display: flex; align-items: center">
                  <el-input
                    v-model="priorityRightValue"
                    size="normal"
                    style="margin-right: 8px; width: 286px"
                    :disabled="true"
                  />
                  <el-button
                    type="primary"
                    size="small"
                    @click="handleClickGetRightValue('priority')"
                    :disabled="dialogType === 'detail'"
                  >
                    获取
                  </el-button>
                </div>
              </div>

              <div>
                <div style="margin: 16px 0 10px 0;">优先购名额</div>
                <div>
                  <el-input
                    v-model="priorityQuota"
                    size="normal"
                    style="margin-right: 8px; width: 286px"
                    :disabled="dialogType === 'detail'"
                  >
                    <div style="padding: 0 10px; height: 40px; line-height: 40px;" slot="suffix">
                      个
                    </div>
                  </el-input>
                </div>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>

      <div
        v-if="dialogType !== 'detail'"
        style="display: flex; align-items: center; justify-content: center; margin: 32px 0 0"
      >
        <el-button @click="handLeClickCancel">取消</el-button>
        <el-button type="primary" @click="handleClickConfirm">确定</el-button>
      </div>
    </el-dialog>

    <el-dialog
      title="获取权益值"
      :visible.sync="rightValueVisible"
      width="400px"
      @close="handleRightValueClose"
    >
      <p>输入名次获取最近一次个人排行榜快照的权益值</p>
      <div>
        <el-input v-model="rank" placeholder="请输入名次">
          <el-button slot="append" icon="el-icon-search" @click="handleClickSearchRightValue" />
        </el-input>
      </div>
      <div style="margin: 10px 0;">
        <span style="margin-right: 8px">权益值：</span>
        <span>{{ rankingContribution }}</span>
      </div>
      <span slot="footer">
        <el-button type="primary" @click="handleSetRightValue">确认</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import dayjs from "dayjs";
import {
  getPriorityPurchases,
  removePriorityPurchase,
  setPriorityPurchases,
  setPriorityPurchasesStatus,
  getTop1000Contribution,
  getBatchNFTList,
} from "@/api/nft";
import DisplayTime from "@/components/DisplayTime.vue";
import LimitRow from "./LimitRow.vue";

const mapCount = (i) => {
  return {
    type: i.type,
    value: i.value || i.inputValue,
    count: +i.count,
  };
};

const mapDesc = (limitConfig) => (i) => {
  const findLimit = limitConfig.find((limit) => limit.value === i.value);
  return {
    type: i.type,
    value: i.value,
    count: +i.count,
    desc: findLimit ? findLimit.desc : i.value,
    isSearchName: !findLimit,
  };
};

export default {
  components: {
    DisplayTime,
    LimitRow,
  },
  watch: {
    editData(newValue, _oldValue) {
      if (newValue && newValue.id) {
        this.fillData(newValue);
      }
    },
    selectedBatchData(newValue, _oldValue) {
      if (newValue) {
        if (this.dialogType === "detail") {
          return;
        }
        const { reserve_purchase_start, priority_purchase_at } = newValue;
        if (priority_purchase_at > 0) {
          this.priorityStatus = true;
        }
        if (reserve_purchase_start > 0) {
          this.reserveStatus = true;
        }
      }
    },
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    batchList: {
      type: Array,
      default: () => [],
    },
    limitConfig: {
      type: Array,
      default: () => [],
    },
    editData: {
      type: Object,
      default: () => {},
    },
    dialogType: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      form: {},
      rules: {
        title: [{ required: true, message: "请输入标题", trigger: "blur" }],
      },
      batchNFTs: [],

      reserveStatus: false,
      reserveLimit: [],
      selectedLimit: [],
      reserveRightValue: "",
      reserveNum: "",
      priorityQuota: "",

      priorityStatus: false,
      priorityLimit: [],
      selectedPLimit: [],
      priorityRightValue: "",

      // 获取权益值
      rightValueVisible: false,
      rightValueType: "",
      rank: "",
      rankingContribution: "",
    };
  },
  computed: {
    selectedBatchData() {
      if (this.form.batch_number && this.form.batch_number > 0) {
        return this.batchList.find((i) => i.batch_number === this.form.batch_number);
      } else {
        return {};
      }
    },
    totalNFT() {
      return this.batchNFTs.reduce((prev, cur) => prev + cur.count, 0);
    },
    limitTypes() {
      const selectedLimitValues = this.selectedLimit.map((i) => i.value);
      return this.limitConfig.map((limit, idx) => {
        return {
          key: idx,
          title: limit.desc,
          disabled: limit.value !== "" && selectedLimitValues.includes(limit.value),
          command: {
            ...limit,
          },
        };
      });
    },
    limitPTypes() {
      const selectedLimitValues = this.selectedPLimit.map((i) => i.value);
      return this.limitConfig.map((limit, idx) => {
        return {
          key: idx,
          title: limit.desc,
          disabled: limit.value !== "" && selectedLimitValues.includes(limit.value),
          command: {
            ...limit,
          },
        };
      });
    },
  },
  filters: {
    formateDate: function(value) {
      if (!value || +value === 0) {
        return "-";
      }
      return dayjs(value * 1000).format("YYYY-MM-DD HH:mm:ss");
    },
  },
  methods: {
    async fetchBatchNFTList(batchNumber) {
      try {
        const { data } = await getBatchNFTList({
          batch_number: batchNumber,
        });
        this.batchNFTs = data.data.nfts || [];
      } catch (error) {
        this.batchNFTs = [];
      }
    },
    handleClose() {
      this.$emit("close");
      // clear data
      this.form = {};
      this.reserveRightValue = "";
      this.reserveNum = "";
      this.priorityQuota = "";
      this.priorityRightValue = "";
      this.reserveRightValue = "";
      this.selectedPLimit = [];
      this.selectedLimit = [];
      this.reserveStatus = false;
      this.priorityStatus = false;
      this.batchNFTs = [];
    },
    handleChangeBatch(batchNumber) {
      this.fetchBatchNFTList(batchNumber);
    },
    fillData(data) {
      this.form = {
        title: data.title,
        batch_number: data.batch_number,
        snapshot_at: data.snapshot_at * 1000,
      };
      this.reserveRightValue = data.reserve_right_value;
      this.reserveNum = data.reserve_num;
      this.priorityQuota = data.priority_quota;
      this.priorityRightValue = data.priority_right_value;
      this.reserveRightValue = data.reserve_right_value;
      this.selectedPLimit = data.priority_txid_limit.map(mapDesc(this.limitConfig));
      this.selectedLimit = data.reserve_txid_limit.map(mapDesc(this.limitConfig));
      this.reserveStatus = data.reserve_status === "enable";
      this.priorityStatus = data.priority_status === "enable";

      if (data.batch_number) {
        this.fetchBatchNFTList(data.batch_number);
      }
    },
    async handleClickConfirm() {
      const isPositiveInt = /^[1-9]\d*$/;
      if (this.priorityQuota && !isPositiveInt.test(this.priorityQuota)) {
        this.$message.error("优先购名额必须为正整数");
        return;
      }

      let params = {
        title: this.form.title,
        batch_number: this.form.batch_number,
        snapshot_at: Math.floor(this.form.snapshot_at / 1000),
        reserve_right_value: +this.reserveRightValue,
        reserve_num: +this.reserveNum,
        priority_quota: +this.priorityQuota,
        priority_right_value: +this.priorityRightValue,
        priority_txid_limit: this.selectedPLimit.map(mapCount),
        reserve_txid_limit: this.selectedLimit.map(mapCount),
        reserve_status: this.reserveStatus ? "enable" : "disable",
        priority_status: this.priorityStatus ? "enable" : "disable",
      };
      if (this.dialogType === "edit") {
        params.id = this.editData.id;
      }
      // console.log(params);
      try {
        await setPriorityPurchases(params);
        this.$emit("success");
      } catch (error) {}
    },
    handLeClickCancel() {
      this.handleClose();
    },
    handleCommand(command) {
      // console.log(command);
      this.selectedLimit = [
        ...this.selectedLimit,
        {
          ...command,
          count: "",
        },
      ];
    },
    handleRemoveLimit(index) {
      const temp = [...this.selectedLimit];
      temp.splice(index, 1);
      this.selectedLimit = [...temp];
    },
    handleChangeValue(inputValue, index) {
      this.selectedLimit = [...this.selectedLimit].map((limit, idx) => {
        if (index === idx) {
          return {
            ...limit,
            inputValue: inputValue,
          };
        }
        return limit;
      });
    },
    handleChangeCount(count, index) {
      this.selectedLimit = [...this.selectedLimit].map((limit, idx) => {
        if (index === idx) {
          return {
            ...limit,
            count: count,
          };
        }
        return limit;
      });
    },

    handlePCommand(command) {
      this.selectedPLimit = [
        ...this.selectedPLimit,
        {
          ...command,
          count: "",
        },
      ];
    },
    handlePRemoveLimit(index) {
      const temp = [...this.selectedPLimit];
      temp.splice(index, 1);
      this.selectedPLimit = [...temp];
    },
    handlePChangeCount(count, index) {
      this.selectedPLimit = [...this.selectedPLimit].map((limit, idx) => {
        if (index === idx) {
          return {
            ...limit,
            count: count,
          };
        }
        return limit;
      });
    },
    handlePChangeValue(inputValue, index) {
      this.selectedPLimit = [...this.selectedPLimit].map((limit, idx) => {
        if (index === idx) {
          return {
            ...limit,
            inputValue: inputValue,
          };
        }
        return limit;
      });
    },
    handleClickGetRightValue(type) {
      this.rightValueVisible = true;
      this.rightValueType = type;
    },
    handleRightValueClose() {
      this.rightValueVisible = false;
      this.rightValueType = "";
      this.rank = "";
      this.rankingContribution = "";
    },
    handleSetRightValue() {
      if (!this.rankingContribution) {
        this.$message.info("请先输入排名查询优先购权益值");
        return;
      }

      if (this.rightValueType === "reserve") {
        this.reserveRightValue = this.rankingContribution;
      }
      if (this.rightValueType === "priority") {
        this.priorityRightValue = this.rankingContribution;
      }
      this.rightValueVisible = false;
    },
    async handleClickSearchRightValue() {
      // console.log(this.rank);
      if (!this.rank) {
        return;
      }
      try {
        const { data } = await getTop1000Contribution(+this.rank, this.rightValueType);
        this.rankingContribution = (data && data.data && data.data.contribution) || 0;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="less">
.el-divider--horizontal {
  margin: 12px 0;
}
</style>
